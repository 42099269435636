import React from "react";

import { Lightbox } from "react-modal-image";
import AddressDialog from "../components/Dialogs/AddressDialog";
import AutocompleteDialog from "../components/Dialogs/AutocompleteDialog";
import ChangePasswordDialog from "../components/Dialogs/ChangePasswordDialog";
import CKEditorDialog from "../components/Dialogs/CKEditorDialog";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import FactoryPackageDialog from "../components/Dialogs/FactoryPackageDialog";
import SelectUserDialog from "../components/Dialogs/SelectUserDialog";
import SettingsEditorDialog from "../components/Dialogs/SettingsEditorDialog";
import SimpleOutputDialog from "../components/Dialogs/SimpleOutputDialog";
import SimpleSelectDialog from "../components/Dialogs/SimpleSelectDialog";
import SimpleTextDialog from "../components/Dialogs/SimpleTextDialog";
import UsergroupListDialog from "../components/Dialogs/UsergroupListDialog";
import SelectEquipmentDialog from "../components/Dialogs/SelectEquipmentDialog";
import ColorDialog from "../components/Dialogs/ColorDialog";
import DateTimeDialog from "../components/Dialogs/DateTimeDialog";

var DialogContext = React.createContext();

const DialogActionTypes = {
    ImageViewerOpen: 0,
    ImageViewerClose: 1,
    AddressDialogOpen: 2,
    AddressDialogClose: 3,
    ChangePasswordDialogOpen: 4,
    ChangePasswordDialogClose: 5,
    ConfirmationDialogOpen: 6,
    ConfirmationDialogClose: 7,
    FactoryPackageDialogOpen: 8,
    FactoryPackageDialogClose: 9,
    SelectUserDialogOpen: 10,
    SelectUserDialogClose: 11,
    SettingsEditorDialogOpen: 12,
    SettingsEditorDialogClose: 13,
    SimpleOutputDialogOpen: 14,
    SimpleOutputDialogClose: 15,
    SimpleTextDialogOpen: 16,
    SimpleTextDialogClose: 17,
    UsergroupListDialogOpen: 18,
    UsergroupListDialogClose: 19,
    AutocompleteDialogOpen: 20,
    AutocompleteDialogClose: 21,
    CKEditorDialogOpen: 22,
    CKEditorDialogClose: 23,
    SimpleSelectDialogOpen: 24,
    SimpleSelectDialogClose: 25,
    SimpleSelectEquipmentDialogOpen: 26,
    SimpleSelectEquipmentDialogClose: 27,
    ColorDialogOpen: 28,
    ColorDialogClose: 29,
    DateTimeDialogOpen: 30,
    DateTimeDialogClose: 31
};
Object.freeze(DialogActionTypes);

function dialogReducer(state, action) {
    switch (action.type) {
        case DialogActionTypes.ImageViewerOpen:
            return {
                ...state,
                imageViewer: {
                    open: true,
                    image: action.image
                }
            };
        case DialogActionTypes.ImageViewerClose:
            return {
                ...state,
                imageViewer: {
                    ...state.imageViewer,
                    open: false
                }
            };
        case DialogActionTypes.AddressDialogOpen:
            return {
                ...state,
                addressDialog: {
                    open: true,
                    mapDisabled: action.mapDisabled,
                    text: action.text,
                    handleOpenMap: action.handleOpenMap
                }
            };
        case DialogActionTypes.AddressDialogClose:
            return {
                ...state,
                addressDialog: {
                    ...state.addressDialog,
                    open: false
                }
            };
        case DialogActionTypes.ChangePasswordDialogOpen:
            return {
                ...state,
                changePaswordDialog: {
                    open: true
                }
            };
        case DialogActionTypes.ChangePasswordDialogClose:
            return {
                ...state,
                changePaswordDialog: {
                    ...state.changePaswordDialog,
                    open: false
                }
            };
        case DialogActionTypes.ConfirmationDialogOpen:
            return {
                ...state,
                confirmationDialog: {
                    open: true,
                    userMessage: action.userMessage,
                    handleConfirm: action.handleConfirm,
                    closeText: action.closeText,
                    confirmText: action.confirmText
                }
            };
        case DialogActionTypes.ConfirmationDialogClose:
            return {
                ...state,
                confirmationDialog: {
                    ...state.confirmationDialog,
                    open: false
                }
            };
        case DialogActionTypes.FactoryPackageDialogOpen:
            return {
                ...state,
                factoryPackageDialog: {
                    open: true,
                    deviceId: action.deviceId
                }
            };
        case DialogActionTypes.FactoryPackageDialogClose:
            return {
                ...state,
                factoryPackageDialog: {
                    ...state.factoryPackageDialog,
                    open: false
                }
            };
        case DialogActionTypes.SelectUserDialogOpen:
            return {
                ...state,
                selectUserDialog: {
                    open: true,
                    handleConfirm: action.handleConfirm
                }
            };
        case DialogActionTypes.SelectUserDialogClose:
            return {
                ...state,
                selectUserDialog: {
                    ...state.selectUserDialog,
                    open: false
                }
            };
        case DialogActionTypes.SettingsEditorDialogOpen:
            return {
                ...state,
                settingsEditorDialog: {
                    open: true,
                    data: action.data,
                    alarmName: action.alarmName
                }
            };
        case DialogActionTypes.SettingsEditorDialogClose:
            return {
                ...state,
                settingsEditorDialog: {
                    ...state.settingsEditorDialog,
                    open: false
                }
            };
        case DialogActionTypes.SimpleOutputDialogOpen:
            return {
                ...state,
                simpleOutputDialog: {
                    open: true,
                    title: action.title,
                    text: action.text,
                    textLabel: action.textLabel,
                    noTextField: action.noTextField
                }
            };
        case DialogActionTypes.SimpleOutputDialogClose:
            return {
                ...state,
                simpleOutputDialog: {
                    ...state.simpleOutputDialog,
                    open: false
                }
            };
        case DialogActionTypes.SimpleTextDialogOpen:
            return {
                ...state,
                simpleTextDialog: {
                    open: true,
                    handleConfirm: action.handleConfirm,
                    initialValue: action.initialValue,
                    textLabel: action.textLabel,
                    title: action.title,
                    maxLength: action.maxLength,
                    lines: action.lines,
                    onChange: action.onChange,
                    allowEmptyOk: action.allowEmptyOk
                }
            };
        case DialogActionTypes.SimpleTextDialogClose:
            return {
                ...state,
                simpleTextDialog: {
                    ...state.simpleTextDialog,
                    open: false
                }
            };
        case DialogActionTypes.UsergroupListDialogOpen:
            return {
                ...state,
                usergroupListDialog: {
                    open: true,
                    list: action.list
                }
            };
        case DialogActionTypes.UsergroupListDialogClose:
            return {
                ...state,
                usergroupListDialog: {
                    ...state.usergroupListDialog,
                    open: false
                }
            };
        case DialogActionTypes.AutocompleteDialogOpen:
            return {
                ...state,
                autocompleteDialog: {
                    open: true,
                    options: action.options,
                    handleConfirm: action.handleConfirm,
                    initialValue: action.initialValue,
                    label: action.label,
                    title: action.title,
                    getOptionLabel: action.getOptionLabel,
                    getOptionSelected: action.getOptionSelected,
                    multiple: action.multiple,
                    scrollElementToWhenEmpty: action.scrollElementToWhenEmpty
                }
            };
        case DialogActionTypes.AutocompleteDialogClose:
            return {
                ...state,
                autocompleteDialog: {
                    ...state.autocompleteDialog,
                    open: false
                }
            };
        case DialogActionTypes.CKEditorDialogOpen:
            return {
                ...state,
                ckEditorDialog: {
                    open: true,
                    handleConfirm: action.handleConfirm,
                    initialValue: action.initialValue,
                    title: action.title
                }
            };
        case DialogActionTypes.CKEditorDialogClose:
            return {
                ...state,
                ckEditorDialog: {
                    ...state.ckEditorDialog,
                    open: false
                }
            };
        case DialogActionTypes.SimpleSelectDialogOpen:
            return {
                ...state,
                simpleSelectDialog: {
                    open: true,
                    handleConfirm: action.handleConfirm,
                    initialValue: action.initialValue,
                    label: action.label,
                    title: action.title,
                    menuItems: action.menuItems
                }
            };
        case DialogActionTypes.SimpleSelectDialogClose:
            return {
                ...state,
                simpleSelectDialog: {
                    ...state.simpleSelectDialog,
                    open: false
                }
            };
        case DialogActionTypes.SimpleSelectEquipmentDialogOpen:
            return {
                ...state,
                selectEquipmentDialog: {
                    open: true,
                    handleConfirm: action.handleConfirm,
                    brandsList: action.brandsList
                }
            };
        case DialogActionTypes.SimpleSelectEquipmentDialogClose:
            return {
                ...state,
                selectEquipmentDialog: {
                    ...state.selectEquipmentDialog,
                    open: false
                }
            };
        case DialogActionTypes.ColorDialogOpen:
            return {
                ...state,
                colorDialog: {
                    open: true,
                    handleConfirm: action.handleConfirm
                }
            };
        case DialogActionTypes.ColorDialogClose:
            return {
                ...state,
                colorDialog: {
                    ...state.colorDialog,
                    open: false
                }
            };
        case DialogActionTypes.DateTimeDialogOpen:
            return {
                ...state,
                dateTimeDialog: {
                    open: true,
                    title: action.title,
                    initialValue: action.initialValue,
                    handleConfirm: action.handleConfirm
                }
            };
        case DialogActionTypes.DateTimeDialogClose:
            return {
                ...state,
                dateTimeDialog: {
                    ...state.dateTimeDialog,
                    open: false
                }
            };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function DialogProvider({ children }) {
    var [state, dispatch] = React.useReducer(dialogReducer, {
        imageViewer: {
            open: false,
            image: []
        },
        addressDialog: {
            open: false,
            mapDisabled: true,
            text: "",
            handleOpenMap: null
        },
        changePaswordDialog: {
            open: false
        },
        confirmationDialog: {
            open: false,
            userMessage: "",
            handleConfirm: null,
            closeText: null,
            confirmText: null
        },
        factoryPackageDialog: {
            open: false,
            deviceId: null
        },
        selectUserDialog: {
            open: false,
            handleConfirm: null
        },
        settingsEditorDialog: {
            open: false,
            data: null,
            alarmName: null
        },
        simpleOutputDialog: {
            open: false,
            title: "",
            text: "",
            textLabel: "",
            noTextField: false
        },
        simpleTextDialog: {
            open: false,
            handleConfirm: null,
            initialValue: "",
            textLabel: "",
            title: "",
            maxLength: null,
            lines: 1,
            onChange: null,
            allowEmptyOk: false
        },
        usergroupListDialog: {
            open: false,
            list: []
        },
        autocompleteDialog: {
            open: false,
            handleConfirm: null,
            initialValue: [],
            label: "",
            options: [],
            title: "",
            getOptionLabel: null,
            getOptionSelected: null,
            multiple: false,
            scrollElementToWhenEmpty: null
        },
        ckEditorDialog: {
            handleConfirm: null,
            initialValue: "",
            open: false,
            title: ""
        },
        simpleSelectDialog: {
            handleConfirm: null,
            initialValue: "",
            open: false,
            label: "",
            title: "",
            menuItems: []
        },
        selectEquipmentDialog: {
            handleConfirm: null,
            open: false,
            brandsList: []
        },
        colorDialog: {
            handleConfirm: null,
            open: false
        },
        dateTimeDialog: {
            handleConfirm: null,
            open: false,
            initialValue: null,
            title: ""
        }
    });

    return (
        <DialogContext.Provider value={dispatch}>
            {children}

            {
                state.imageViewer.open && (
                    <Lightbox
                        hideZoom
                        large={state.imageViewer.image.src}
                        alt={state.imageViewer.image.alt}
                        onClose={() => dispatch({ type: DialogActionTypes.ImageViewerClose })}
                    />
                )
            }
            <AddressDialog
                handleClose={() => dispatch({ type: DialogActionTypes.AddressDialogClose })}
                isOpened={state.addressDialog.open}
                handleOpenMap={state.addressDialog.handleOpenMap}
                isOpenMapDisabled={state.addressDialog.mapDisabled}
                text={state.addressDialog.text}
            />
            <ChangePasswordDialog
                handleClose={() => dispatch({ type: DialogActionTypes.ChangePasswordDialogClose })}
                isOpened={state.changePaswordDialog.open}
            />
            <ConfirmationDialog
                handleClose={() => dispatch({ type: DialogActionTypes.ConfirmationDialogClose })}
                isOpened={state.confirmationDialog.open}
                userMessage={state.confirmationDialog.userMessage}
                handleConfirm={state.confirmationDialog.handleConfirm}
                closeText={state.confirmationDialog.closeText}
                confirmText={state.confirmationDialog.confirmText}
            />
            <FactoryPackageDialog
                handleClose={() => dispatch({ type: DialogActionTypes.FactoryPackageDialogClose })}
                isOpened={state.factoryPackageDialog.open}
                deviceId={state.factoryPackageDialog.deviceId}
            />
            <SelectUserDialog
                handleClose={() => dispatch({ type: DialogActionTypes.SelectUserDialogClose })}
                isOpened={state.selectUserDialog.open}
                handleConfirm={state.selectUserDialog.handleConfirm} />
            <SettingsEditorDialog
                handleClose={() => dispatch({ type: DialogActionTypes.SettingsEditorDialogClose })}
                isOpened={state.settingsEditorDialog.open}
                data={state.settingsEditorDialog.data}
                alarmName={state.settingsEditorDialog.alarmName}
            />
            <SimpleOutputDialog
                handleClose={() => dispatch({ type: DialogActionTypes.SimpleOutputDialogClose })}
                isOpened={state.simpleOutputDialog.open}
                title={state.simpleOutputDialog.title}
                text={state.simpleOutputDialog.text}
                textLabel={state.simpleOutputDialog.textLabel}
                noTextField={state.simpleOutputDialog.noTextField}
            />
            <SimpleTextDialog
                handleClose={() => dispatch({ type: DialogActionTypes.SimpleTextDialogClose })}
                isOpened={state.simpleTextDialog.open}
                handleConfirm={state.simpleTextDialog.handleConfirm}
                initialValue={state.simpleTextDialog.initialValue}
                textLabel={state.simpleTextDialog.textLabel}
                title={state.simpleTextDialog.title}
                maxLength={state.simpleTextDialog.maxLength}
                lines={state.simpleTextDialog.lines}
                onChange={state.simpleTextDialog.onChange}
                allowEmptyOk={state.simpleTextDialog.allowEmptyOk}
            />
            <UsergroupListDialog
                handleClose={() => dispatch({ type: DialogActionTypes.UsergroupListDialogClose })}
                isOpened={state.usergroupListDialog.open}
                list={state.usergroupListDialog.list}
            />
            <AutocompleteDialog
                handleClose={() => dispatch({ type: DialogActionTypes.AutocompleteDialogClose })}
                isOpened={state.autocompleteDialog.open}
                handleConfirm={state.autocompleteDialog.handleConfirm}
                initialValue={state.autocompleteDialog.initialValue}
                label={state.autocompleteDialog.label}
                options={state.autocompleteDialog.options}
                title={state.autocompleteDialog.title}
                getOptionLabel={state.autocompleteDialog.getOptionLabel}
                getOptionSelected={state.autocompleteDialog.getOptionSelected}
                multiple={state.autocompleteDialog.multiple}
                scrollElementToWhenEmpty={state.autocompleteDialog.scrollElementToWhenEmpty}
            />
            <CKEditorDialog
                handleClose={() => dispatch({ type: DialogActionTypes.CKEditorDialogClose })}
                handleConfirm={state.ckEditorDialog.handleConfirm}
                initialValue={state.ckEditorDialog.initialValue}
                isOpened={state.ckEditorDialog.open}
                title={state.ckEditorDialog.title}
            />
            <SimpleSelectDialog
                handleClose={() => dispatch({ type: DialogActionTypes.SimpleSelectDialogClose })}
                handleConfirm={state.simpleSelectDialog.handleConfirm}
                initialValue={state.simpleSelectDialog.initialValue}
                isOpened={state.simpleSelectDialog.open}
                label={state.simpleSelectDialog.label}
                title={state.simpleSelectDialog.title}
                menuItems={state.simpleSelectDialog.menuItems}
            />
            <SelectEquipmentDialog
                handleClose={() => dispatch({ type: DialogActionTypes.SimpleSelectEquipmentDialogClose })}
                isOpened={state.selectEquipmentDialog.open}
                brandsList={state.selectEquipmentDialog.brandsList ? state.selectEquipmentDialog.brandsList : []}
                handleConfirm={state.selectEquipmentDialog.handleConfirm}
            />
            <ColorDialog
                handleClose={() => dispatch({ type: DialogActionTypes.ColorDialogClose })}
                isOpened={state.colorDialog.open}
                handleConfirm={state.colorDialog.handleConfirm}
            />
            <DateTimeDialog
                handleClose={() => dispatch({ type: DialogActionTypes.DateTimeDialogClose })}
                isOpened={state.dateTimeDialog.open}
                title={state.dateTimeDialog.title}
                initialValue={state.dateTimeDialog.initialValue}
                handleConfirm={state.dateTimeDialog.handleConfirm}
            />
        </DialogContext.Provider>
    );
}

function useDialogContext() {
    var context = React.useContext(DialogContext);
    if (context === undefined) {
        throw new Error("useLayoutState must be used within a DialogProvider");
    }
    return context;
}

export { DialogProvider, useDialogContext, DialogActionTypes };