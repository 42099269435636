import React, { useState, useEffect, useRef } from "react";
import { IconButton, Typography, Divider, Grid } from "@mui/material";
import { Settings as SettingsIcon } from "@mui/icons-material";

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../context/LayoutContext";

import { generateSidebarPageList } from "../SidebarPageListGenerator";
import { useInterval } from "../useInterval";
import { UserPermissions } from "../enums/UserRoles";
import StyledDrawer from "./StyledDrawer/StyledDrawer";
import { useTranslation } from "react-i18next";
import { useBackend } from "../context/BackendContext";
import { useNavigate } from "react-router";

function Sidebar() {
  const layoutDispatch = useLayoutDispatch();
  const backend = useBackend();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isSidebarOpened } = useLayoutState();
  const [isPermanent, setPermanent] = useState(true);
  const [showChatStatus, setShowChatStatus] = useState(false);
  const [showRequestsStatus, setShowRequestsStatus] = useState(false);
  const [
    showAnyWithPendingUsergroupMembers,
    setShowAnyWithPendingUsergroupMembers,
  ] = useState(false);
  const isInitialized = useRef(false);
  const [structure, setStructure] = useState([]);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    setStructure(generateSidebarPageList(t, {}));
  }, [t]);

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;
      var permissions =
        JSON.parse(localStorage.getItem("permissions") || "[]") || [];

      if (permissions.includes(UserPermissions.CHAT_ANY)) {
        setShowChatStatus(true);
        updateChatStatus(backend);
      }

      if (permissions.includes(UserPermissions.LIST_TECHSUPPORT_REQUESTS)) {
        setShowRequestsStatus(true);
        updateRequestsStatus(backend);
      }

      if (permissions.includes(UserPermissions.USERS_LIST)) {
        setShowAnyWithPendingUsergroupMembers(true);
        updateAnyWithPendingUsergroupMembers(backend);
      }
    }
  }, [backend]);

  const updateRequestsStatus = () => {
    backend.bckTechsupportRequestsAnyOpened().then((json) => {
      if (json.code === 0) {
        setStructure((prev) => {
          let newStructure = [...prev];
          let unreadLink = newStructure.find((s) => s.id === 10);
          if (unreadLink) unreadLink.showBadge = json.content;
          return newStructure;
        });
      }
    });
  };

  const updateAnyWithPendingUsergroupMembers = () => {
    backend.bckUsersAnyWithPendingUsergroupMembers().then((json) => {
      if (json.code === 0) {
        setStructure((prev) => {
          let newStructure = [...prev];
          let unreadLink = newStructure.find((s) => s.id === 1);
          if (unreadLink) unreadLink.showBadge = json.content;
          return newStructure;
        });
      }
    });
  };

  const updateChatStatus = (backend) => {
    backend.bckChatAnyUnreadMessages().then((json) => {
      if (json.code === 0) {
        setStructure((prev) => {
          let newStructure = [...prev];
          let unreadLink = newStructure.find((s) => s.id === 11);
          if (unreadLink) unreadLink.showBadge = json.content;
          return newStructure;
        });
      }
    });
  };

  useInterval(() => {
    if (showChatStatus) {
      updateChatStatus(backend);
    }
    if (showRequestsStatus) {
      updateRequestsStatus(backend);
    }
    if (showAnyWithPendingUsergroupMembers) {
      updateAnyWithPendingUsergroupMembers(backend);
    }
  }, 5000);

  return (
    <StyledDrawer
      isPermanent={isPermanent}
      isSidebarOpened={isSidebarOpened}
      structure={structure}
      handleClose={() => toggleSidebar(layoutDispatch, false)}
      handleOpen={() => toggleSidebar(layoutDispatch, true)}
      content={
        <>
          {isSidebarOpened ? (
            <div style={{ margin: "12px", minWidth: "240px" }}>
              <div style={{ display: "flex" }}>
                <div style={{ alignSelf: "flex-end", minWidth: "216px" }}>
                  <Typography
                    variant="h5"
                    weight="medium"
                    style={{ width: "180px" }}
                  >
                    {localStorage.getItem("userName")}
                  </Typography>
                  <Grid container direction="row">
                    <Grid item style={{ flexGrow: 1 }}>
                      <Typography
                        style={{
                          marginTop: "8px",
                          fontSize: "10pt",
                          color: "#A0A0A0",
                        }}
                        component="a"
                        color="primary"
                      >
                        {localStorage.getItem("userMail")}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        style={{ width: "24px", height: "24px", padding: 0 }}
                        onClick={() => {
                          navigate("/app/manageAccount");
                          toggleSidebar(layoutDispatch, false);
                        }}
                      >
                        <SettingsIcon
                          style={{ width: "16px", height: "16px" }}
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <Divider />
            </div>
          ) : (
            <></>
          )}
        </>
      }
    />
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = 900;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default Sidebar;
