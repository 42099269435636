import React, { useState, useEffect, useCallback } from "react";

import {
  Typography,
  IconButton,
  Paper,
  MenuItem,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Autocomplete,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";

import SelectAddressObjectDialog from "../../components/Dialogs/SelectAddressObjectDialog";
import ReactFileReader from "react-file-reader";
import ImageUploadImage from "../../images/ImageUpload.jpg";

import {
  AddCircle as AddCircleIcon,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
} from "@mui/icons-material";

import { useBackend } from "../../context/BackendContext";
import { ArticleTypes } from "../../enums/Articles/ArticleTypes";
import {
  ArticleElementTypes,
  ArticleElementTypesByName,
} from "../../enums/Articles/ArticleElementTypes";
import ElementProperties from "./ElementProperties";
import { GenerateInteger } from "../../helpers/RandomGenerator";
import { ArticleStatuses } from "../../enums/Articles/ArticleStatuses";
import {
  LanguageConverter,
  LanguageConverterInverse,
} from "../../enums/LanguageConverter";
//import ChipInput from "material-ui-chip-input";
import {
  ElementFields,
  ElementTypesAndFields,
  ElementTypesAndRequiredFields,
} from "./ElementFields";
import { ArticleElementFillingByName } from "../../enums/Articles/ArticleElementFilling";
import ElementCard from "./ElementCard";
import update from "immutability-helper";
import { UserPermissions } from "../../enums/UserRoles";
import { ResponseCode } from "../../enums/ResponseCode";
import ElementsRender from "./ElementsRender";
import { DateTimePicker } from "@mui/x-date-pickers";

export default function ArticleFull() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [, setForceUpdate] = useState({});
  const [originalFullInfo, setOriginalFullInfo] = useState({});
  const [fullInfo, setFullInfo] = useState({});
  const [selectedElement, setSelectedElement] = useState(null);
  const [title, setTitle] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [publishDate, setPublishDate] = useState(null);
  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [isAddressDialogOpened, setIsAddressDialogOpened] = useState(false);
  const [geoTags, setGeoTags] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [tagsInputValue, setTagsInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [availableTags, setAvailableTags] = useState([]);
  const [elements, setElements] = useState([]);
  const [viewOnlyMode, setViewOnlyMode] = useState(true);

  const ArticleTypeMenuItems = Object.keys(ArticleTypes).map((i) => (
    <MenuItem key={i} value={i}>
      {t(ArticleTypes[i])}
    </MenuItem>
  ));

  const ArticleStatusItems = Object.keys(ArticleStatuses).map((i) => (
    <MenuItem key={i} value={i}>
      {t(ArticleStatuses[i])}
    </MenuItem>
  ));

  const refreshInfo = useCallback(
    (articleId) => {
      backend.bckArticlesFullInfo(articleId).then((json) => {
        if (json.code === 0) {
          let article = json.content;
          setFullInfo(article);
          setOriginalFullInfo(JSON.parse(JSON.stringify(article)));

          setTitle(article.title ?? "");
          setType(article.type);
          setStatus(article.status);
          setDescription(article.description ?? "");
          setLanguages(
            article.languages.map((l) =>
              LanguageConverterInverse[l].toUpperCase()
            ) ?? []
          );
          setGeoTags(article.geoTags ?? []);
          setTags(article.tags ? article.tags : []);
          if (article.elements) {
            article.elements.sort((a, b) => (a.order > b.order ? 1 : -1));
          }
          setElements(article.elements ?? []);
          if (article.publishDate) {
            setPublishDate(Date.parse(article.publishDate));
          } else {
            setPublishDate(null);
          }
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
    },
    [t, backend]
  );

  useEffect(() => {
    var params = queryString.parse(location.search);
    if (params.id) {
      refreshInfo(params.id);
    } else {
      setError("Article ID is not set");
    }
    backend.bckAddressGetCountries().then((json) => {
      if (json.code === 0) {
        setCountriesList(json.content);
      }
    });
    backend.bckArticlesTags().then((json) => {
      if (json.code === 0) {
        setAvailableTags(json.content);
      }
    });
    let viewOnly = false;
    let userId = parseInt(localStorage.getItem("userId"));
    var permissions =
      JSON.parse(localStorage.getItem("permissions") || "[]") || [];
    if (
      !permissions.includes(UserPermissions.ARTICLES_VIEW_DRAFTS) &&
      userId !== fullInfo.userId
    ) {
      viewOnly = true;
    }
    setViewOnlyMode(viewOnly);
  }, [location]);

  const handleAddNewElement = () => {
    const articleElementTypeMenuItems = Object.keys(ArticleElementTypes).map(
      (i) => (
        <MenuItem key={i} value={parseInt(i)}>
          {t(ArticleElementTypes[i])}
        </MenuItem>
      )
    );

    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: ArticleElementTypesByName.BUTTON,
      label: t("Type"),
      title: t("Type"),
      menuItems: articleElementTypeMenuItems,
      handleConfirm: (value) => {
        let newElement = { tempId: GenerateInteger(), type: value };
        if (
          ElementTypesAndFields[value].some((f) => f === ElementFields.Fillings)
        ) {
          newElement.widthFilling = ArticleElementFillingByName.FILL;
          newElement.heightFilling = ArticleElementFillingByName.WRAP;
        }
        let newElements = [...fullInfo.elements, newElement];
        setFullInfo({ ...fullInfo, elements: newElements });
        setElements(newElements);
      },
    });
  };

  const handleSaveClick = () => {
    if (fullInfo.elements) {
      for (let i = 0; i < fullInfo.elements.length; ++i) {
        let element = fullInfo.elements[i];
        let requiredFields = ElementTypesAndRequiredFields[element.type];
        let errorFields = [];
        for (let j = 0; j < requiredFields.length; ++j) {
          let field = requiredFields[j];
          if (!element[field]) {
            errorFields.push(field);
          }
        }
        if (errorFields.length > 0) {
          setSelectedElement(element);
          let errorFieldsStr = errorFields.join(", ");
          dialogDispatch({
            type: DialogActionTypes.SimpleOutputDialogOpen,
            title: "",
            text: t("Fill required fields", { fields: errorFieldsStr }),
            textLabel: "",
            noTextField: true,
          });
          return;
        }
      }
    }

    if (originalFullInfo.status != fullInfo.status) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Change article status?", {
          oldStatus: t(ArticleStatuses[originalFullInfo.status]),
          newStatus: t(ArticleStatuses[fullInfo.status]),
        }),
        handleConfirm: () => {
          handleSaveConfirmed();
        },
      });
    } else {
      handleSaveConfirmed();
    }
  };

  const handleSaveConfirmed = () => {
    fullInfo.elements.forEach((el, i) => (el.order = i));
    backend.bckArticlesCreateOrUpdate(fullInfo).then((json) => {
      if (json.code === 0) {
        alert(t("Save successfull"));
        setOriginalFullInfo(JSON.parse(JSON.stringify(fullInfo)));
      } else if (json.code === 3) {
        setError(json.message);
      } else {
        setError(t("REQUEST_ERROR"));
      }
    });
  };

  const handleRemoveElement = (el) => {
    console.log(fullInfo);
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete element?"),
      handleConfirm: () => {
        console.log(fullInfo);
        let newElements = [...fullInfo.elements];
        let targetIndex = newElements.indexOf(el);
        newElements.splice(targetIndex, 1);
        setFullInfo((prev) => {
          return { ...fullInfo, elements: newElements };
        });
        setElements(newElements);
      },
    });
  };

  const handleFullscreenClick = () => {
    setFullScreenMode(!fullScreenMode);
  };

  const handleUploadImage = (file) => {
    if (viewOnlyMode) return;
    if (fullInfo.fullImageUrl) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Overwrite image?"),
        handleConfirm: () => {
          handleUploadImageAccepted(file);
        },
      });
    } else {
      handleUploadImageAccepted(file);
    }
  };

  const handleUploadImageAccepted = (file) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadArticleImage(fullInfo.id, file).then((json) => {
      if (json.code === 0) {
        let newInfo = { ...fullInfo, fullImageUrl: json.content };
        setFullInfo(newInfo);
      } else {
        setError(json.message);
      }
    });
  };

  const handleUrlUpload = (element, file, onSuccess) => {
    if (viewOnlyMode) return;
    if (element.url) {
      dialogDispatch({
        type: DialogActionTypes.ConfirmationDialogOpen,
        userMessage: t("Overwrite image?"),
        handleConfirm: () => {
          handleUploadUrlAccepted(element, file, onSuccess);
        },
      });
    } else {
      handleUploadUrlAccepted(element, file, onSuccess);
    }
  };

  const handleUploadUrlAccepted = (element, file, onSuccess) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend
      .bckFilesUploadArticleElementImage(element.id ?? 0, file)
      .then((json) => {
        if (json.code === 0) {
          onSuccess(json.content);
        } else {
          setError(json.message);
        }
      });
  };

  const handleLanguagesChanged = (...params) => {
    setLanguages(params[1]);
    let newInfo = {
      ...fullInfo,
      languages: params[1].map((l) => LanguageConverter[l.toLowerCase()]),
    };
    setFullInfo(newInfo);
  };

  const handleTagsChanged = (newTags) => {
    setTags(newTags);
    let newInfo = { ...fullInfo, tags: newTags };
    setFullInfo(newInfo);
  };

  const handleAddressDialogClose = () => {
    setIsAddressDialogOpened(false);
  };

  const handleAddressDialogConfirm = (value, level) => {
    setIsAddressDialogOpened(false);
    if (geoTags.some((v) => v === value)) return;
    let newSelectedLocations = [...geoTags, value];
    setGeoTags(newSelectedLocations);
    let newInfo = { ...fullInfo, geoTags: newSelectedLocations };
    setFullInfo(newInfo);
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setFullInfo((prev) =>
      update(prev, {
        elements: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prev.elements[dragIndex]],
          ],
        },
      })
    );
    setElements((prev) =>
      update(prev, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev[dragIndex]],
        ],
      })
    );
  }, []);

  const handleRemoveArticle = () => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete article?"),
      handleConfirm: () => {
        backend.bckArticlesRemove(fullInfo.id).then((json) => {
          navigate(`/app/articles`);
        });
      },
    });
  };

  return (
    <div style={{ overflow: "auto", maxHeight: "85vh" }}>
      <Paper
        style={{
          padding: "24px",
          marginBottom: "16px",
          minWidth: "720px",
          marginRight: "16px",
          display: fullScreenMode ? "none" : null,
        }}
      >
        <Typography color="secondary">{error}</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <div style={{ flexBasis: "20%", margin: "16px" }}>
            <ReactFileReader
              handleFiles={(files) => handleUploadImage(files[0])}
              fileTypes={["*.png", "*.jpg"]}
            >
              {fullInfo.fullImageUrl ? (
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    cursor: "pointer",
                  }}
                  src={fullInfo.fullImageUrl}
                  alt=""
                />
              ) : (
                <img
                  style={{
                    maxWidth: "100%",
                    maxHeight: "100%",
                    cursor: "pointer",
                  }}
                  src={ImageUploadImage}
                  alt=""
                />
              )}
            </ReactFileReader>
          </div>
          <div
            style={{
              flexBasis: "40%",
              flexGrow: 1,
              marginRight: "16px",
              marginLeft: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TextField
              label={t("Title")}
              value={title}
              onChange={(e) => {
                fullInfo.title = e.target.value;
                setTitle(e.target.value);
              }}
              style={{ marginBottom: "12px" }}
            />
            <FormControl style={{ marginBottom: "12px", maxWidth: "20ch" }}>
              <InputLabel>{t("Type")}</InputLabel>
              <Select
                label={t("Type")}
                value={type}
                onChange={(e) => {
                  fullInfo.type = e.target.value;
                  setType(e.target.value);
                }}
              >
                {ArticleTypeMenuItems}
              </Select>
            </FormControl>
            <TextField
              label={t("Description")}
              margin="dense"
              value={description}
              variant="outlined"
              multiline
              minRows="4"
              maxRows="4"
              fullWidth
              onChange={(e) => {
                fullInfo.description = e.target.value;
                setDescription(e.target.value);
              }}
            />
            <div
              style={{
                minWidth: "64ch",
                marginBottom: "16px",
                marginRight: "16px",
              }}
            >
              <Autocomplete
                multiple
                size="small"
                value={tags}
                inputValue={tagsInputValue}
                options={availableTags}
                onChange={(...params) => handleTagsChanged(params[1])}
                getOptionLabel={(option) => option.text}
                getOptionSelected={(a, b) => a.text === b.text}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(e) => setTagsInputValue(e.target.value)}
                    label={t("Tags")}
                  />
                )}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    if (
                      tagsInputValue &&
                      !availableTags.some((at) => at.text === tagsInputValue)
                    ) {
                      event.defaultMuiPrevented = true;

                      let newType = {
                        id: GenerateInteger(),
                        text: tagsInputValue,
                      };
                      let newAvailableTags = [...availableTags, newType];
                      setAvailableTags(newAvailableTags);
                      let newTags = [...tags, newType];
                      handleTagsChanged(newTags);
                      setTagsInputValue("");
                    }
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              flexBasis: "25%",
              margin: "16px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControl style={{ marginBottom: "12px", maxWidth: "20ch" }}>
              <InputLabel>{t("Status")}</InputLabel>
              <Select
                label={t("Status")}
                value={status}
                onChange={(e) => {
                  fullInfo.status = e.target.value;
                  setStatus(e.target.value);
                }}
              >
                {ArticleStatusItems}
              </Select>
            </FormControl>
            <DateTimePicker
              style={{ marginRight: "16px", marginBottom: "16px" }}
              label={t("Publish time")}
              format="yyyy-MM-dd HH:mm:ss"
              value={publishDate}
              onChange={(v) => {
                fullInfo.publishDate = v?.toJSON();
                setPublishDate(v);
              }}
            />
            {viewOnlyMode ? (
              <Typography color="secondary">
                {t("You can't edit this article")}
              </Typography>
            ) : (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  onClick={handleSaveClick}
                  style={{ marginRight: "16px" }}
                  variant="contained"
                  color="primary"
                >
                  {t("Save")}
                </Button>
                <Button
                  onClick={handleRemoveArticle}
                  variant="contained"
                  color="secondary"
                >
                  {t("Delete")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </Paper>
      <Paper
        style={{
          padding: "24px",
          marginBottom: "16px",
          minWidth: "720px",
          marginRight: "16px",
          display: fullScreenMode ? "none" : null,
        }}
      >
        <Typography>
          <b>{t("Filters")}</b>
        </Typography>
        <div
          style={{
            minWidth: "64ch",
            marginBottom: "16px",
            marginRight: "16px",
          }}
        >
          <Autocomplete
            multiple
            size="small"
            value={languages}
            options={Object.keys(LanguageConverter).map((k) => k.toUpperCase())}
            onChange={handleLanguagesChanged}
            renderInput={(params) => (
              <TextField {...params} label={t("Languages")} />
            )}
          />
        </div>
        <div
          style={{
            minWidth: "128ch",
            marginTop: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {/*<ChipInput*/}
          {/*    inputValue=""*/}
          {/*    value={geoTags}*/}
          {/*    label={t("Location")}*/}
          {/*    onDelete={(chip, i) => {*/}
          {/*        setGeoTags((prev) => { let copy = [...prev]; copy.splice(i, 1); return copy; });*/}
          {/*        setFullInfo((prev) => { prev.geoTags.splice(i, 1); return prev; });*/}
          {/*    }}*/}
          {/*    style={{ minWidth: "48ch", marginRight: "16px", marginBottom: "16px" }}*/}
          {/*/>*/}
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsAddressDialogOpened(true);
            }}
            style={{ marginRight: "16px" }}
          >
            {t("Select")}
          </Button>
        </div>
      </Paper>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          minWidth: "720px",
          marginBottom: "12px",
          marginRight: "16px",
          justifyContent: fullScreenMode ? "center" : null,
        }}
      >
        <Paper
          style={{
            flexBasis: "15%",
            padding: "24px",
            marginRight: "16px",
            display: fullScreenMode ? "none" : null,
            overflow: "auto",
            maxHeight: "80vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              <b>{t("Elements")}</b>
            </Typography>
            <IconButton
              color="primary"
              onClick={(e) => {
                handleAddNewElement();
              }}
            >
              <AddCircleIcon />
            </IconButton>
          </div>
          {elements.map((element, index) => (
            <ElementCard
              selectedElement={selectedElement}
              key={element.id ?? element.tempId}
              index={index}
              id={element.id ?? element.tempId}
              element={element}
              moveCard={moveCard}
              handleRemoveElement={handleRemoveElement}
              onClick={() => setSelectedElement(element)}
            />
          ))}
        </Paper>
        <Paper
          style={{
            flexBasis: "60%",
            padding: "24px",
            flexGrow: 1,
            overflow: "auto",
            maxHeight: "80vh",
            maxWidth: "120ch",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>
              <b>{t("Preview")}</b>
            </Typography>
            <IconButton color="primary" onClick={handleFullscreenClick}>
              {fullScreenMode ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </div>
          <ElementsRender
            elements={elements}
            setSelectedElement={setSelectedElement}
          />
        </Paper>
        <Paper
          style={{
            flexBasis: "25%",
            padding: "24px",
            marginLeft: "16px",
            display: fullScreenMode ? "none" : null,
            overflow: "auto",
            maxHeight: "80vh",
          }}
        >
          <Typography>
            <b>{t("Properties")}</b>
          </Typography>
          {selectedElement ? (
            <ElementProperties
              element={selectedElement}
              setElement={(update) => {
                setSelectedElement((prev) => update(prev));
                setForceUpdate({});
              }}
              handleUrlUpload={handleUrlUpload}
            />
          ) : (
            <></>
          )}
        </Paper>
      </div>

      <SelectAddressObjectDialog
        isOpened={isAddressDialogOpened}
        countriesList={countriesList}
        handleClose={handleAddressDialogClose}
        handleConfirm={handleAddressDialogConfirm}
      />
    </div>
  );
}
