import { unsignedToSignedByte, signedToUnsignedByte, binToString, stringToBin } from "./ByteUtils";
import { SettingsStructureTypesByName } from "../../enums/SettingsStructureTypes";

const saveInt = (data, startByte, length, value) => {
    for (let i = 0; i < length; ++i) {
        data[startByte + i] = value % 256;
        value = value / 256;
    }
    return value;
}

const saveBit = (data, startByte, lengthByte, startBit, value) => {
    let byteValue = loadInt(data, startByte, lengthByte);
    let mask = 1;
    for (var i = 0; i < startBit; i++) {
        mask = (mask << 1);
    }
    if (value) {
        byteValue |= mask;
    } else {
        byteValue &= (~mask);
    }
    saveInt(data, startByte, lengthByte, byteValue);
    return value;
}

const saveValue = (prebuiltSettings, data, structure, value) => {
    if (!structure) return 0;
    let startByte = structure.StartByte;
    let startBit = structure.StartBit;
    switch (structure.Mapper) {
        case SettingsStructureTypesByName["UInt"]:
            return saveInt(data, startByte, 4, value);
        case SettingsStructureTypesByName["UShort"]:
            return saveInt(data, startByte, 2, value);
        case SettingsStructureTypesByName["UShortAsFloat"]:
            return saveInt(data, startByte, 2, value * structure.Divider);
        case SettingsStructureTypesByName["UByte"]:
        case SettingsStructureTypesByName["UByteWithBits"]:
            if (structure.LengthBit) {
                for (let i = structure.StartBit; i < structure.StartBit + structure.LengthBit; i += 1) {
                    data[startByte] &= ~(1 << i);
                }
                const shiftedVal = 255 & (value << structure.StartBit);
                data[startByte] = data[startByte] | shiftedVal;
                return value;
            }
            return saveInt(data, startByte, 1, value);
        case SettingsStructureTypesByName["UByteAsFloat"]:
            return saveInt(data, startByte, 1, value * structure.Divider);
        case SettingsStructureTypesByName["SByte"]:
            return saveInt(data, startByte, 1, signedToUnsignedByte(value));
        case SettingsStructureTypesByName["Bit"]:
            return saveBit(data, startByte, 1, startBit, value);
        case SettingsStructureTypesByName["InvertedBit"]:
            return saveBit(data, startByte, 1, startBit, value ? 0 : 1)
        case SettingsStructureTypesByName["UByteOption"]:
            if (structure.Option2Value) {
                return saveInt(data, startByte, 1, structure.Option2Value[value]);
            } else {
                return saveInt(data, startByte, 1, value);
            }
        case SettingsStructureTypesByName["BitOption"]:
            if (structure.Option2Value) {
                return saveBit(data, startByte, 1, structure.StartBit, structure.Option2Value[value]);
            } else {
                return saveBit(data, startByte, 1, structure.StartBit, value);
            }
        case SettingsStructureTypesByName["ShortTime"]:
            return saveInt(data, startByte, value, 2);
        case SettingsStructureTypesByName["DmsByte"]:
            return saveInt(data, startByte, 1, (127 - value * 127 / 100) >>> 0);
        case SettingsStructureTypesByName["CharString"]:
            let byteArray = stringToBin(value);
            for (let i = 0; i < structure.LengthByte; ++i) {
                if (i < byteArray.length) {
                    data[structure.StartByte + i] = byteArray[i];
                } else {
                    data[structure.StartByte + i] = 0;
                }
            }
            return value;
        case SettingsStructureTypesByName["BitFromInt"]:
            return saveBit(data, startByte, 4, startBit, value);
        case SettingsStructureTypesByName["Virtual"]:
            let virtualValue = structure.Option2Value[value];
            for (let i = 0; i < virtualValue.length; i++) {
                saveValue(prebuiltSettings, data, structure.OptionStructs[i], virtualValue[i]);
            }
            return value;
        case SettingsStructureTypesByName["Structure"]:
            break;
        case SettingsStructureTypesByName["UByteSiren"]:
            return saveInt(data, startByte, 1, value);
        case SettingsStructureTypesByName["Instance"]:
            return saveValue(prebuiltSettings, data, prebuiltSettings[structure.PrototypeId], value);
        case SettingsStructureTypesByName["Array"]:
            break;
        case SettingsStructureTypesByName["Union"]:
            structure.Children.forEach((c, i) => saveValue(prebuiltSettings, data, c, value[i]));
            return value;
        default:
            return -1;
    }

}

const loadInt = (data, startByte, length) => {
    let acc = 0;
    for (let i = 0; i < length; ++i) {
        acc |= (data[startByte + i] << (8 * i));
    }
    return acc;
}

const loadBit = (data, startByte, lengthByte, startBit) => {
    let byteValue = loadInt(data, startByte, lengthByte);
    let mask = 1;
    for (var i = 0; i < startBit; i++) {
        mask = (mask << 1);
    }
    return (byteValue & mask) / mask;
}

const loadValue = (prebuiltSettings, data, structure) => {
    if (!structure) return 0;
    let startByte = structure.StartByte;
    let startBit = structure.StartBit;
    switch (structure.Mapper) {
        case SettingsStructureTypesByName["UInt"]:
            return loadInt(data, startByte, 4);
        case SettingsStructureTypesByName["UShort"]:
            return loadInt(data, startByte, 2);
        case SettingsStructureTypesByName["UShortAsFloat"]:
            return loadInt(data, startByte, 2) / structure.Divider;
        case SettingsStructureTypesByName["UByte"]:
        case SettingsStructureTypesByName["UByteWithBits"]:
            const value = loadInt(data, startByte, 1);
            if (structure.LengthBit) {
                let mask = 1;
                for (let i = 0; i < structure.LengthBit - 1; i += 1) {
                    mask = (mask << 1) + 1;
                }
                const shiftedValue = structure.StartBit ? value >> structure.StartBit : value;
                return shiftedValue & mask;
            }
            return value;
        case SettingsStructureTypesByName["UByteAsFloat"]:
            return loadInt(data, startByte, 1) / structure.Divider;
        case SettingsStructureTypesByName["SByte"]:
            return unsignedToSignedByte(loadInt(data, startByte, 1));
        case SettingsStructureTypesByName["Bit"]:
            return loadBit(data, startByte, 1, startBit);
        case SettingsStructureTypesByName["InvertedBit"]:
            return loadBit(data, startByte, 1, startBit) ? 0 : 1
        case SettingsStructureTypesByName["UByteOption"]:
            let uByteOptionValue = loadInt(data, startByte, 1);
            return parseInt(Object.keys(structure.Option2Value).find(key => structure.Option2Value[key].some(v => v === uByteOptionValue)));
        case SettingsStructureTypesByName["BitOption"]:
            let bitOptionValue = loadBit(data, startByte, 1, structure.StartBit);
            if (structure.Option2Value) {
                return Object.keys(structure.Option2Value).find(key => structure.Option2Value[key].some(v => v === bitOptionValue));
            } else {
                return bitOptionValue;
            }
        case SettingsStructureTypesByName["ShortTime"]:
            return loadInt(data, startByte, 2);
        case SettingsStructureTypesByName["DmsByte"]:
            return (100 - loadInt(data, startByte, 1) * 100 / 127) >>> 0;
        case SettingsStructureTypesByName["CharString"]:
            let endByte = startByte + (structure.LengthByte - 1);
            let byteArray = data.slice(startByte, endByte);
            let str = binToString(byteArray);
            return str;
        case SettingsStructureTypesByName["BitFromInt"]:
            return loadBit(data, startByte, 4, startBit);
        case SettingsStructureTypesByName["Virtual"]:
            let virtualValues = structure.OptionStructs.map(os => {
                let virtualStructure = prebuiltSettings[os];
                return loadValue(prebuiltSettings, data, virtualStructure)
            });
            return Object.keys(structure.Option2Value).find(key => structure.Option2Value[key].every((v, i) => v === virtualValues[i]));
        case SettingsStructureTypesByName["Structure"]:
            break;
        case SettingsStructureTypesByName["UByteSiren"]:
            return loadInt(data, startByte, 1);
        case SettingsStructureTypesByName["Instance"]:
            return loadValue(prebuiltSettings, data, prebuiltSettings[structure.PrototypeId]);
        case SettingsStructureTypesByName["Array"]:
            break;
        case SettingsStructureTypesByName["Union"]:
            return structure.Children.map(c => loadValue(prebuiltSettings, data, c));
        default:
            return -1;
    }
}

const flattenSettings = (settingsInfo, chosenAlarm) => {
    let settingsArray = [];
    let intermediateNodes = []; // inputs and outputs structures have to be saved wout flattening
    let alarmMenu = Object.values(settingsInfo.AlarmMenus).find(am => am.AlarmId === chosenAlarm);
    let rootSettings = settingsInfo.Structures[alarmMenu.RootStructureId];
    settingsArray = [...rootSettings.Children];
    let finished = 0;
    while (!finished) {
        finished = 1;
        settingsArray = settingsArray.reduce((acc, curr, i) => {
            curr.StartByte = curr.StartByte ? curr.StartByte : 0;
            curr.LengthByte = curr.LengthByte ? curr.LengthByte : 0;
            curr.StartBit = curr.StartBit ? curr.StartBit : 0;
            if (curr.Mapper === SettingsStructureTypesByName.Structure) {
                let shiftedChildren = JSON.parse(JSON.stringify(curr.Children));
                shiftedChildren.forEach(c => {
                    c.StartByte = c.StartByte ? c.StartByte : 0;
                    c.StartBit = c.StartBit ? c.StartBit : 0;
                    c.StartByte = c.StartByte + curr.StartByte + curr.LengthByte;
                    c.StartBit = c.StartBit + curr.StartBit;
                });
                acc = [...acc, ...shiftedChildren];
                finished = 0;
            } else if (curr.Mapper === SettingsStructureTypesByName.Instance) {
                let prototypeStructure = JSON.parse(JSON.stringify(settingsInfo.Structures[curr.PrototypeId]));
                prototypeStructure.Id = curr.Id;
                prototypeStructure.StartByte = curr.StartByte;
                prototypeStructure.StartBit = curr.StartBit;
                acc.push(prototypeStructure);
                if (curr.Name === 'hdw_in_inp_correspondence' || curr.Name === 'hdw_out_ch_correspondence') {
                    intermediateNodes.push(curr);
                }
                finished = 0;
            } else {
                acc.push(curr);
            }
            return acc;
        }, []);
    }
    settingsArray = [...settingsArray, ...intermediateNodes];
    settingsArray = settingsArray.reduce((p, c, i, a) => {
        if (p[c.Id]) console.log(`Duplicate in settings array: ${c.Id}`);
        return ({ ...p, [c.Id]: c })
    }, {});
    return settingsArray;
}

export { saveValue, loadValue, flattenSettings };