import React, { useState, useEffect, } from "react";

import {
    Typography,
    Paper,
    TextField
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

import queryString from "query-string"
import {
    useLocation
} from "react-router-dom";

import PackageSearcher from "../components/PackageSearcher"
import { useTranslation } from "react-i18next";

let searchTimeout = null;

const deviceIdRegex = /^[0-9]{0,16}$/;

export default function PackageSearchPage() {    
    const location = useLocation();
    const { t } = useTranslation();
    const [error, setError] = useState("");
    const [searchDeviceIdInput, setSearchDeviceIdInput] = useState("");
    const [searchDeviceId, setSearchDeviceId] = useState("");

    useEffect(() => {
        var params = queryString.parse(location.search);
        if (params.deviceId && deviceIdRegex.test(params.deviceId)) {
            setSearchDeviceIdInput(params.deviceId);
            setSearchDeviceId(params.deviceId);
        }
    }, [location]);

    const startSearch = (newDeviceId) => {
        if (!newDeviceId || newDeviceId.trim() === "") {
            setSearchDeviceId("");
        } else {
            setSearchDeviceId(newDeviceId);
        }
    };

    const handleSearchKeyUp = (e) => {
        if (e.keyCode === 13) {
            window.clearTimeout(searchTimeout);
            startSearch();
        }
    };

    const onSearchChange = (e) => {
        let newInput = e.target.value;
        if (newInput === '' || deviceIdRegex.test(newInput)) {
            setSearchDeviceIdInput(newInput);
            window.clearTimeout(searchTimeout);
            searchTimeout = window.setTimeout(() => {
                startSearch(newInput);
            }, 1000);
        }
    };

    return (
        <>
            <Paper style={{ overflow: "auto", padding: "24px", maxHeight: "85vh", maxWidth: "120ch", margin: 'auto' }}>
                <Typography variant="h5" color="textSecondary">
                    {t("Package search")}
                </Typography>
                <Typography color="secondary">{error}</Typography>
                <div style={{ margin: 'auto' }}>
                    <TextField
                        label={t("Device ID")}
                        value={searchDeviceIdInput}
                        onChange={onSearchChange}
                        onKeyUp={handleSearchKeyUp}
                        style={{ marginRight: "12px" }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <PackageSearcher deviceId={searchDeviceId} setError={setError} />
            </Paper>
        </>);
}
