import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Typography,
  IconButton,
  Paper,
  Switch,
  Button,
  Tabs,
  Tab,
  MenuItem,
} from "@mui/material";

import { GenerateInteger } from "../../helpers/RandomGenerator";

import { useTranslation } from "react-i18next";
import {
  useDialogContext,
  DialogActionTypes,
} from "../../context/DialogContext";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import { LanguageConverter } from "../../enums/LanguageConverter";

import { Edit as EditIcon } from "@mui/icons-material";
import EquipmentStatusesTable from "../../components/Tables/EquipmentStatusesTable/EquipmentStatusesTable";
import { useBackend } from "../../context/BackendContext";
import { ResponseCode } from "../../enums/ResponseCode";
import { CarModelTypes } from "../../enums/CarModelTypes";
import { DigitalBusesGroups } from "../../enums/DigitalBusesGroups";
import styles from "./styles.module.css";
import CarMountingPointGuideEditor from "./CarMountingPointGuideEditor";
import CarMountingSchemesTable from "../../components/Tables/CarMountingSchemesTable";

export default function CarEquipmentFull() {
  const dialogDispatch = useDialogContext();
  const backend = useBackend();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [error, setError] = useState("");
  const [originalFullInfo, setOriginalFullInfo] = useState({
    canBlock: false,
    immobKeyless: false,
  });
  const [fullInfo, setFullInfo] = useState({
    canBlock: false,
    immobKeyless: false,
  });
  const [availableTypes, setAvailableTypes] = useState([]);
  const [notExistingTypes, setNotExistingTypes] = useState([]);
  const [tabValue, setTabValue] = useState(1);
  const lastRequestId = useRef(0);
  const updatesLockCounter = useRef(0);

  const refreshInfo = useCallback(
    (equipmentId, language) => {
      ++updatesLockCounter.current;
      var id = GenerateInteger();
      lastRequestId.current = id;
      backend
        .bckCarsGetEquipmentInfo(equipmentId, language, true)
        .then((json) => {
          --updatesLockCounter.current;
          if (lastRequestId.current === id) {
            if (json.code === 0) {
              if (updatesLockCounter.current === 0) {
                setFullInfo(json.content);
                setOriginalFullInfo(JSON.parse(JSON.stringify(json.content)));
              }
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          }
        });
    },
    [t, backend]
  );

  useEffect(() => {
    if (!fullInfo || !fullInfo.mountingPointGuides || !availableTypes) return;
    let newNotExistingTypes = availableTypes.filter(
      (t) => !fullInfo.mountingPointGuides.find((g) => g.type.id === t.id)
    );
    setNotExistingTypes(newNotExistingTypes);
  }, [fullInfo, availableTypes]);

  useEffect(() => {
    return () => {
      lastRequestId.current = null;
    };
  }, []);

  useEffect(() => {
    var params = queryString.parse(location.search);
    if (params.id) {
      refreshInfo(params.id, LanguageConverter[i18n.language]);
    } else {
      setError("Model ID is not set");
    }
    backend
      .bckCarsMountingPointGuideTypeGet(LanguageConverter[i18n.language])
      .then((json) => {
        if (json.code === 0) {
          setAvailableTypes(() => {
            json.content.sort((a, b) => a.order - b.order);
            return json.content;
          });
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  }, [location, i18n.language]);

  const sendUpdateRequest = (info) => {
    ++updatesLockCounter.current;
    let id = GenerateInteger();
    lastRequestId.current = id;
    backend
      .bckCarsCreateOrUpdateEquipment(
        info.id,
        info.modelId,
        info.carName,
        info.carCode,
        info.canBlock,
        info.canBlockAlarms,
        info.canBlockPrompt,
        info.immobKeyless,
        info.immobKeylessAlarms,
        info.immobKeylessPrompt,
        info.description1,
        info.description2,
        info.statuses,
        LanguageConverter[i18n.language],
        info.digitalBusesGroups
      )
      .then((json) => {
        --updatesLockCounter.current;
        if (json.code === 0) {
          setFullInfo(info);
        } else if (json.code === 3) {
          setError(json.message);
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  const handleEditTitle = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (title) => {
        setFullInfo({ ...fullInfo, carName: title });
      },
      initialValue: fullInfo.carName,
      textLabel: t("Title"),
      title: t("Title"),
    });
  };

  const handleEditDescription1 = () => {
    dialogDispatch({
      type: DialogActionTypes.CKEditorDialogOpen,
      handleConfirm: (description) => {
        setFullInfo({ ...fullInfo, description1: description });
      },
      initialValue: fullInfo.description1,
      title: t("Description"),
    });
  };

  const handleEditDescription2 = () => {
    dialogDispatch({
      type: DialogActionTypes.CKEditorDialogOpen,
      handleConfirm: (description) => {
        setFullInfo({ ...fullInfo, description2: description });
      },
      initialValue: fullInfo.description2,
      title: t("Description"),
    });
  };

  const handleEditCan = () => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (canCode) => {
        setFullInfo({ ...fullInfo, carCode: canCode });
      },
      initialValue: fullInfo.carCode,
      textLabel: "CAN",
      title: "CAN",
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const onChangeCanBlock = (newValue) => {
    setFullInfo({ ...fullInfo, canBlock: newValue });
  };

  const handleEditCanBlockPrompt = () => {
    dialogDispatch({
      type: DialogActionTypes.CKEditorDialogOpen,
      handleConfirm: (newValue) => {
        setFullInfo({ ...fullInfo, canBlockPrompt: newValue });
      },
      initialValue: fullInfo.canBlockPrompt,
      title: t("CAN block prompt"),
    });
  };

  const onChangeAutostart = (newValue) => {
    setFullInfo({ ...fullInfo, immobKeyless: newValue });
  };

  const handleEditAutostartPrompt = () => {
    dialogDispatch({
      type: DialogActionTypes.CKEditorDialogOpen,
      handleConfirm: (newValue) => {
        setFullInfo({ ...fullInfo, immobKeylessPrompt: newValue });
      },
      initialValue: fullInfo.immobKeylessPrompt,
      title: t("Keyless autostart prompt"),
    });
  };

  const onShowPromptClick = (s) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleOutputDialogOpen,
      text: <div dangerouslySetInnerHTML={{ __html: s.prompt }} />,
      textLabel: "",
      title: "",
      noTextField: true,
    });
  };

  const onCellClick = (s, commandOrStatus, number) => {
    let newStatuses = [...fullInfo.statuses];
    let status = newStatuses.find((s1) => s1.id === s.id);
    if (!status) return;
    if (commandOrStatus === 0) {
      if (status.commandCan.some((cc) => cc === number)) {
        status.commandCan = status.commandCan.filter((cc) => cc !== number);
      } else {
        status.commandCan.push(number);
      }
    } else {
      if (status.statusCan.some((cc) => cc === number)) {
        status.statusCan = status.statusCan.filter((cc) => cc !== number);
      } else {
        status.statusCan.push(number);
      }
    }
    setFullInfo({ ...fullInfo, statuses: newStatuses });
  };

  const onEditPromptClick = (s) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newValue) => {
        s.prompt = newValue;
        setFullInfo({ ...fullInfo });
      },
      initialValue: s.prompt,
      textLabel: t("Description"),
      title: t("Description"),
      allowEmptyOk: true,
    });
  };

  const handleSaveClick = () => {
    setOriginalFullInfo(fullInfo);
    sendUpdateRequest(fullInfo);
  };

  const isInfoDifferent = (info1, info2) => {
    return JSON.stringify(info1) !== JSON.stringify(info2);
  };

  const handleAddMountingPointGuide = () => {
    let typesInMenu = notExistingTypes;
    const availableTypesMenuItems = typesInMenu.map((t) => (
      <MenuItem key={t.id} value={t.id}>
        {t.name}
      </MenuItem>
    ));

    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: typesInMenu[0].id,
      label: t("Type"),
      title: t("Type"),
      menuItems: availableTypesMenuItems,
      handleConfirm: (value) => {
        let selectedType = typesInMenu.find((t) => t.id === value);
        backend
          .bckCarsMountingPointGuideCreateOrUpdate(
            fullInfo.id,
            LanguageConverter[i18n.language],
            { type: selectedType, language: LanguageConverter[i18n.language] }
          )
          .then((json) => {
            if (json.code === 0) {
              let newId = json.content;
              let newItem = {
                id: newId,
                type: selectedType,
                images: [],
                language: LanguageConverter[i18n.language],
              };
              let newList = [newItem, ...fullInfo.mountingPointGuides];
              newList.sort((a, b) => a.type.order - b.type.order);
              setFullInfo((prev) => ({
                ...prev,
                mountingPointGuides: newList,
              }));
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleEditMountingPointGuideSubtitle = (guide) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (subtitle) => {
        backend
          .bckCarsMountingPointGuideCreateOrUpdate(
            fullInfo.id,
            LanguageConverter[i18n.language],
            { id: guide.id, subtitle: subtitle }
          )
          .then((json) => {
            if (json.code === 0) {
              guide.subtitle = subtitle;
              let newList = [...fullInfo.mountingPointGuides];
              newList.sort((a, b) => a.type.order - b.type.order);
              setFullInfo((prev) => ({
                ...prev,
                mountingPointGuides: newList,
              }));
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: guide.subtitle,
      textLabel: t("Subtitle"),
      title: t("Subtitle"),
    });
  };

  const handleEditMountingPointGuideDescription = (guide) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (description) => {
        backend
          .bckCarsMountingPointGuideCreateOrUpdate(
            fullInfo.id,
            LanguageConverter[i18n.language],
            { id: guide.id, description: description }
          )
          .then((json) => {
            if (json.code === 0) {
              guide.description = description;
              let newList = [...fullInfo.mountingPointGuides];
              newList.sort((a, b) => a.type.order - b.type.order);
              setFullInfo((prev) => ({
                ...prev,
                mountingPointGuides: newList,
              }));
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
      initialValue: guide.description,
      textLabel: t("Description"),
      title: t("Description"),
      lines: 3,
    });
  };

  const handleEditMountingPointGuideLanguage = (guide, e) => {
    let newLanguageStr = e.target.value.toLowerCase();
    let newLanguageInt = LanguageConverter[newLanguageStr];
    backend
      .bckCarsMountingPointGuideCreateOrUpdate(
        fullInfo.id,
        LanguageConverter[i18n.language],
        { id: guide.id, language: newLanguageInt }
      )
      .then((json) => {
        if (json.code === 0) {
          guide.language = newLanguageInt;
          let newList = [...fullInfo.mountingPointGuides];
          newList.sort((a, b) => a.type.order - b.type.order);
          setFullInfo((prev) => ({ ...prev, mountingPointGuides: newList }));
        } else if (json.code === ResponseCode.ACCESS_DENIED) {
          setError(t("AUTH_ERROR"));
        } else {
          setError(t("REQUEST_ERROR"));
        }
      });
  };

  const handleEditMountingPointGuideType = (guide) => {
    let typesInMenu = availableTypes;
    const availableTypesMenuItems = typesInMenu.map((t) => (
      <MenuItem key={t.id} value={t.id}>
        {t.name}
      </MenuItem>
    ));

    dialogDispatch({
      type: DialogActionTypes.SimpleSelectDialogOpen,
      initialValue: guide.type.id,
      label: t("Type"),
      title: t("Type"),
      menuItems: availableTypesMenuItems,
      handleConfirm: (value) => {
        let selectedType = typesInMenu.find((t) => t.id === value);
        backend
          .bckCarsMountingPointGuideCreateOrUpdate(
            fullInfo.id,
            LanguageConverter[i18n.language],
            { id: guide.id, type: selectedType }
          )
          .then((json) => {
            if (json.code === 0) {
              guide.type = selectedType;
              let newList = [...fullInfo.mountingPointGuides];
              newList.sort((a, b) => a.type.order - b.type.order);
              setFullInfo((prev) => ({
                ...prev,
                mountingPointGuides: newList,
              }));
            } else if (json.code === ResponseCode.ACCESS_DENIED) {
              setError(t("AUTH_ERROR"));
            } else {
              setError(t("REQUEST_ERROR"));
            }
          });
      },
    });
  };

  const handleDeleteMountingPointGuide = (guide) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete guide?"),
      handleConfirm: () => {
        backend.bckCarsMountingPointGuideRemove(guide.id).then((json) => {
          if (json.content) {
            setFullInfo((prev) => {
              let newGuideList = [...fullInfo.mountingPointGuides];
              let targetIndex = newGuideList.findIndex(
                (r) => r.id === guide.id
              );
              newGuideList.splice(targetIndex, 1);
              return { ...prev, mountingPointGuides: newGuideList };
            });
          }
        });
      },
    });
  };

  const handleAddMountingPointGuideImage = (guide) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Add new empty image?"),
      handleConfirm: () => {
        backend
          .bckCarsMountingPointGuideImageCreateOrUpdate(guide.id, {
            id: null,
            order: 0,
          })
          .then((json) => {
            if (json.content) {
              setFullInfo((prev) => {
                let newGuideList = [...fullInfo.mountingPointGuides];
                let target = newGuideList.find((r) => r.id === guide.id);
                let newImageList = [
                  { id: json.content, order: 0 },
                  ...target.images,
                ];
                target.images = newImageList;
                return { ...prev, mountingPointGuides: newGuideList };
              });
            }
          });
      },
    });
  };

  const handleAddMountingScheme = () => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Add new empty scheme?"),
      handleConfirm: () => {
        backend
          .bckCarsMountingSchemeCreateOrUpdate(
            fullInfo.id,
            LanguageConverter[i18n.language],
            { id: null, order: 0, language: LanguageConverter[i18n.language] }
          )
          .then((json) => {
            if (json.content) {
              let newId = json.content;
              let newItem = {
                id: newId,
                order: 0,
                language: LanguageConverter[i18n.language],
              };
              let newList = [newItem, ...fullInfo.mountingSchemes];
              newList.sort((a, b) => a.order - b.order);
              setFullInfo((prev) => ({ ...prev, mountingSchemes: newList }));
            }
          });
      },
    });
  };

  const handleAddMountingMap = () => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Add new empty map?"),
      handleConfirm: () => {
        backend
          .bckCarsMountingMapCreateOrUpdate(
            fullInfo.id,
            LanguageConverter[i18n.language],
            { id: null, order: 0, language: LanguageConverter[i18n.language] }
          )
          .then((json) => {
            if (json.content) {
              let newId = json.content;
              let newItem = {
                id: newId,
                order: 0,
                language: LanguageConverter[i18n.language],
              };
              let newList = [newItem, ...fullInfo.mountingMaps];
              newList.sort((a, b) => a.order - b.order);
              setFullInfo((prev) => ({ ...prev, mountingMaps: newList }));
            }
          });
      },
    });
  };

  const handleUpdateMountingPointGuideImageOrder = (guide, image, order) => {
    backend
      .bckCarsMountingPointGuideImageCreateOrUpdate(guide.id, {
        id: image.id,
        order: order,
      })
      .then((json) => {
        if (json.content) {
          setFullInfo((prev) => {
            let newGuideList = [...fullInfo.mountingPointGuides];
            let targetGuide = newGuideList.find((r) => r.id === guide.id);
            let newImageList = [...targetGuide.images];
            let targetImage = newImageList.find((i) => i.id === image.id);
            targetImage.order = order;
            newImageList.sort((a, b) => a.order - b.order);
            targetGuide.images = newImageList;
            return { ...prev, mountingPointGuides: newGuideList };
          });
        }
      });
  };

  const handleMoveMountingPointGuideImageLeft = (guide, image) => {
    let targetIndex = guide.images.findIndex((i) => i.id === image.id);
    if (targetIndex === 0) return;
    let imageToLeft = guide.images[targetIndex - 1];
    handleUpdateMountingPointGuideImageOrder(
      guide,
      image,
      imageToLeft.order - 1 < 0 ? 0 : imageToLeft.order - 1
    );
  };

  const handleMoveMountingPointGuideImageRight = (guide, image) => {
    let targetIndex = guide.images.findIndex((i) => i.id === image.id);
    if (targetIndex === guide.images.length - 1) return;
    let imageToLeft = guide.images[targetIndex + 1];
    handleUpdateMountingPointGuideImageOrder(
      guide,
      image,
      imageToLeft.order + 1
    );
  };

  const handleDeleteMountingPointGuideImage = (guide, image) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete image?"),
      handleConfirm: () => {
        backend.bckCarsMountingPointGuideImageRemove(image.id).then((json) => {
          if (json.content) {
            setFullInfo((prev) => {
              let newGuideList = [...fullInfo.mountingPointGuides];
              let targetGuide = newGuideList.find((r) => r.id === guide.id);
              let newImageList = [...targetGuide.images];
              let targetIndex = newImageList.findIndex(
                (r) => r.id === image.id
              );
              newImageList.splice(targetIndex, 1);
              targetGuide.images = newImageList;
              return { ...prev, mountingPointGuides: newGuideList };
            });
          }
        });
      },
    });
  };

  const handleDeleteMountingScheme = (scheme) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete mounting scheme?"),
      handleConfirm: () => {
        backend.bckCarsMountingSchemeRemove(scheme.id).then((json) => {
          if (json.content) {
            let newList = [...fullInfo.mountingSchemes];
            let targetIndex = newList.findIndex((r) => r.id === scheme.id);
            newList.splice(targetIndex, 1);
            setFullInfo((prev) => ({ ...prev, mountingSchemes: newList }));
          }
        });
      },
    });
  };

  const handleDeleteMountingMap = (map) => {
    dialogDispatch({
      type: DialogActionTypes.ConfirmationDialogOpen,
      userMessage: t("Delete mounting map?"),
      handleConfirm: () => {
        backend.bckCarsMountingMapRemove(map.id).then((json) => {
          if (json.content) {
            let newList = [...fullInfo.mountingMaps];
            let targetIndex = newList.findIndex((r) => r.id === map.id);
            newList.splice(targetIndex, 1);
            setFullInfo((prev) => ({ ...prev, mountingMaps: newList }));
          }
        });
      },
    });
  };

  const handleUploadMountingPointGuideImage = (guide, image, file) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend
      .bckFilesUploadMountingPointGuideImage(image.id, file)
      .then((json) => {
        if (json.code === 0) {
          setFullInfo((prev) => {
            let fileId = json.content;
            let newGuideList = [...prev.mountingPointGuides];
            let targetGuide = newGuideList.find((r) => r.id === guide.id);
            let newImageList = [...targetGuide.images];
            let targetImage = newImageList.find((i) => i.id === image.id);
            targetImage.name = fileId;
            targetImage.url = null;
            targetGuide.images = newImageList;
            return { ...prev, mountingPointGuides: newGuideList };
          });
        }
      });
  };

  const handleUploadMountingScheme = (scheme, file) => {
    if (file.size > 16 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadMountingScheme(scheme.id, file).then((json) => {
      if (json.code === 0) {
        setFullInfo((prev) => {
          let newList = [...prev.mountingSchemes];
          let foundIndex = newList.findIndex((t) => t.id === scheme.id);
          newList[foundIndex].name = json.content;
          newList[foundIndex].url = null;
          return { ...prev, mountingSchemes: newList };
        });
      }
    });
  };

  const handleUploadMountingMap = (map, file) => {
    if (file.size > 40 * 1024 * 1024) {
      alert(t("File is too large"));
      return;
    }

    backend.bckFilesUploadCarMountingMap(map.id, file).then((json) => {
      if (json.code === 0) {
        setFullInfo((prev) => {
          let newList = [...prev.mountingMaps];
          let foundIndex = newList.findIndex((t) => t.id === map.id);
          newList[foundIndex].name = json.content;
          newList[foundIndex].url = null;
          return { ...prev, mountingMaps: newList };
        });
      }
    });
  };

  const handleDownloadMountingScheme = (scheme) => {
    if (scheme.url) {
      window.open(scheme.url, `_blank`);
    } else {
      window.open(
        "https://specialist.alarmtrade.ru/uploads/cars/mounting_schemes/" +
          scheme.name,
        `_blank`
      );
    }
  };

  const handleDownloadMountingMap = (map) => {
    if (map.url) {
      window.open(map.url, `_blank`);
    } else {
      window.open(
        "https://specialist.alarmtrade.ru/uploads/cars/mounting_maps/" +
          map.name,
        `_blank`
      );
    }
  };

  const handleEditMountingSchemeOrder = (scheme) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newOrder) => {
        setFullInfo((prev) => {
          let newList = [...prev.mountingSchemes];
          let foundIndex = newList.findIndex((t) => t.id === scheme.id);
          newList[foundIndex].order = newOrder;
          newList.sort((a, b) => a.order - b.order);
          return { ...prev, mountingSchemes: newList };
        });
        backend.bckCarsMountingSchemeCreateOrUpdate(
          fullInfo.id,
          i18n.language,
          { id: scheme.id, order: newOrder }
        );
      },
      initialValue: "" + scheme.order,
      textLabel: t("Order"),
      title: t("Order"),
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const handleEditMountingSchemeLanguage = (scheme, e) => {
    let newLanguageStr = e.target.value.toLowerCase();
    let newLanguageInt = LanguageConverter[newLanguageStr];
    setFullInfo((prev) => {
      let newList = [...prev.mountingSchemes];
      let foundIndex = newList.findIndex((t) => t.id === scheme.id);
      newList[foundIndex].language = newLanguageInt;
      newList.sort((a, b) => a.order - b.order);
      return { ...prev, mountingSchemes: newList };
    });
    backend.bckCarsMountingSchemeCreateOrUpdate(fullInfo.id, i18n.language, {
      id: scheme.id,
      language: newLanguageInt,
    });
  };

  const handleEditMountingMapOrder = (map) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newOrder) => {
        setFullInfo((prev) => {
          let newList = [...prev.mountingMaps];
          let foundIndex = newList.findIndex((t) => t.id === map.id);
          newList[foundIndex].order = newOrder;
          newList.sort((a, b) => a.order - b.order);
          return { ...prev, mountingMaps: newList };
        });
        backend.bckCarsMountingMapCreateOrUpdate(fullInfo.id, i18n.language, {
          id: map.id,
          order: newOrder,
        });
      },
      initialValue: "" + map.order,
      textLabel: t("Order"),
      title: t("Order"),
      onChange: (e) => {
        let filteredValue = e.target.value.replace(/[^0-9]/g, "");
        return filteredValue.substr(0, 4);
      },
    });
  };

  const handleEditMountingSchemeDescription = (scheme) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newDescription) => {
        setFullInfo((prev) => {
          let newList = [...prev.mountingSchemes];
          let foundIndex = newList.findIndex((t) => t.id === scheme.id);
          newList[foundIndex].description = newDescription;
          return { ...prev, mountingSchemes: newList };
        });
        backend.bckCarsMountingSchemeCreateOrUpdate(
          fullInfo.id,
          i18n.language,
          { id: scheme.id, description: newDescription }
        );
      },
      initialValue: scheme.description,
      textLabel: t("Description"),
      title: t("Description"),
    });
  };

  const handleEditMountingMapDescription = (map) => {
    dialogDispatch({
      type: DialogActionTypes.SimpleTextDialogOpen,
      handleConfirm: (newDescription) => {
        setFullInfo((prev) => {
          let newList = [...prev.mountingMaps];
          let foundIndex = newList.findIndex((t) => t.id === map.id);
          newList[foundIndex].description = newDescription;
          return { ...prev, mountingMaps: newList };
        });
        backend.bckCarsMountingMapCreateOrUpdate(fullInfo.id, i18n.language, {
          id: map.id,
          description: newDescription,
        });
      },
      initialValue: map.description,
      textLabel: t("Description"),
      title: t("Description"),
    });
  };

  const handleEditMountingMapLanguage = (map, e) => {
    let newLanguageStr = e.target.value.toLowerCase();
    let newLanguageInt = LanguageConverter[newLanguageStr];
    setFullInfo((prev) => {
      let newList = [...prev.mountingMaps];
      let foundIndex = newList.findIndex((t) => t.id === map.id);
      newList[foundIndex].language = newLanguageInt;
      return { ...prev, mountingMaps: newList };
    });
    backend.bckCarsMountingMapCreateOrUpdate(fullInfo.id, i18n.language, {
      id: map.id,
      language: newLanguageInt,
    });
  };

  const handleEditDigitalBuses = () => {
    dialogDispatch({
      type: DialogActionTypes.AutocompleteDialogOpen,
      initialValue: fullInfo.digitalBusesGroups,
      label: t("Interfaces"),
      title: t("Interfaces"),
      options: Object.keys(DigitalBusesGroups).map((t) => parseInt(t)),
      getOptionLabel: (option) => t(DigitalBusesGroups[option]),
      multiple: true,
      handleConfirm: (digitalBusesGroups) => {
        setFullInfo({ ...fullInfo, digitalBusesGroups: digitalBusesGroups });
      },
    });
  };

  return (
    <>
      <Paper
        style={{
          overflow: "auto",
          padding: "24px",
          maxHeight: "85vh",
          maxWidth: "100%",
        }}
      >
        <Grid container direction="column" spacing={2}>
          <Typography variant="h5" color="textSecondary">
            {t("Equipment")}
          </Typography>
          <Typography color="secondary">{error}</Typography>
          <Grid item>
            <Button
              onClick={handleSaveClick}
              disabled={!isInfoDifferent(originalFullInfo, fullInfo)}
              variant="contained"
              color="primary"
            >
              {t("Save")}
            </Button>
          </Grid>
          <div className={styles.imageRowColumn}>
            <div className={styles.imageRow}>
              <div className={styles.imageRowImageDiv}>
                {fullInfo.carImageUrl ? (
                  <img
                    style={{
                      width: "100%",
                      maxWidth: "256px",
                      maxHeight: "192px",
                    }}
                    src={fullInfo.carImageUrl}
                    alt=""
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className={styles.imageRowText}>
                <Typography variant="h4">
                  {fullInfo.carBrandName} {fullInfo.carModelName}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <b>{t("Equipment")}: </b>
                    {fullInfo.carName}
                  </Typography>
                  <IconButton color="primary" onClick={handleEditTitle}>
                    <EditIcon />
                  </IconButton>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <b>{t("Type")}: </b>
                    {t(CarModelTypes[fullInfo.modelType])}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    <b>CAN: </b>
                    {fullInfo.carCode}
                  </Typography>
                  <IconButton color="primary" onClick={handleEditCan}>
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
          <div style={{ maxWidth: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("CAN block")}: </b>
              </Typography>
              <Switch
                color="primary"
                checked={fullInfo.canBlock}
                onChange={(e) => onChangeCanBlock(e.target.checked)}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>{t("CAN block prompt")}: </b>
                </Typography>
                <IconButton color="primary" onClick={handleEditCanBlockPrompt}>
                  <EditIcon />
                </IconButton>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: fullInfo.canBlockPrompt }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Keyless autostart")}: </b>
              </Typography>
              <Switch
                color="primary"
                checked={fullInfo.immobKeyless}
                onChange={(e) => onChangeAutostart(e.target.checked)}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>{t("Keyless autostart prompt")}: </b>
                </Typography>
                <IconButton color="primary" onClick={handleEditAutostartPrompt}>
                  <EditIcon />
                </IconButton>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: fullInfo.immobKeylessPrompt,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>{t("Interfaces")}: </b>
                {fullInfo.digitalBusesGroups
                  ?.map((f) => DigitalBusesGroups[f])
                  .join(",")}
              </Typography>
              <IconButton color="primary" onClick={handleEditDigitalBuses}>
                <EditIcon />
              </IconButton>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>{t("Description")} 1: </b>
                </Typography>
                <IconButton color="primary" onClick={handleEditDescription1}>
                  <EditIcon />
                </IconButton>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: fullInfo.description1 }}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>{t("Description")} 2: </b>
                </Typography>
                <IconButton color="primary" onClick={handleEditDescription2}>
                  <EditIcon />
                </IconButton>
              </div>
              <div
                dangerouslySetInnerHTML={{ __html: fullInfo.description2 }}
              />
            </div>
            <Tabs
              value={tabValue}
              onChange={(event, newValue) => {
                setTabValue(newValue);
              }}
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab label={t("Statuses and commands")} value={1} />
              <Tab label={t("Mounting points")} value={2} />
              <Tab label={t("Mounting schemes")} value={3} />
              <Tab label={t("Mounting maps")} value={4} />
            </Tabs>
            {((tabValue) => {
              switch (tabValue) {
                case 1:
                  return fullInfo.statuses && fullInfo.digitalBusesGroups ? (
                    <div style={{ marginTop: "32px", overflowX: "auto" }}>
                      <EquipmentStatusesTable
                        data={fullInfo.statuses}
                        isEditable={true}
                        onShowPromptClick={onShowPromptClick}
                        onCellClick={onCellClick}
                        onEditPromptClick={onEditPromptClick}
                        digitalBusesGroups={fullInfo.digitalBusesGroups}
                      />
                    </div>
                  ) : (
                    <></>
                  );
                case 2:
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "100%",
                        marginBottom: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "16px",
                        }}
                      >
                        <Button
                          disabled={
                            !notExistingTypes || notExistingTypes.length === 0
                          }
                          onClick={handleAddMountingPointGuide}
                          variant="contained"
                          color="primary"
                        >
                          {t("Add")}
                        </Button>
                        <Button
                          onClick={() => {
                            navigate(`/app/carMountingPointGuideTypes`);
                          }}
                          variant="contained"
                          color="primary"
                        >
                          {t("Edit types")}
                        </Button>
                      </div>
                      {fullInfo && fullInfo.mountingPointGuides ? (
                        fullInfo.mountingPointGuides.map((guide) => (
                          <div key={guide.id} style={{ marginBottom: "16px" }}>
                            <CarMountingPointGuideEditor
                              key={guide.id}
                              guide={guide}
                              handleAddImage={() =>
                                handleAddMountingPointGuideImage(guide)
                              }
                              handleDeleteImage={(image) =>
                                handleDeleteMountingPointGuideImage(
                                  guide,
                                  image
                                )
                              }
                              handleUploadImage={(image, file) =>
                                handleUploadMountingPointGuideImage(
                                  guide,
                                  image,
                                  file
                                )
                              }
                              handleMoveImageLeft={(image) =>
                                handleMoveMountingPointGuideImageLeft(
                                  guide,
                                  image
                                )
                              }
                              handleMoveImageRight={(image) =>
                                handleMoveMountingPointGuideImageRight(
                                  guide,
                                  image
                                )
                              }
                              handleEditDescription={() =>
                                handleEditMountingPointGuideDescription(guide)
                              }
                              handleEditLanguage={(e) =>
                                handleEditMountingPointGuideLanguage(guide, e)
                              }
                              handleEditSubtitle={() =>
                                handleEditMountingPointGuideSubtitle(guide)
                              }
                              handleEditType={() =>
                                handleEditMountingPointGuideType(guide)
                              }
                              handleDelete={() =>
                                handleDeleteMountingPointGuide(guide)
                              }
                            />
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                case 3:
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "100%",
                        marginBottom: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "16px",
                        }}
                      >
                        <Button
                          onClick={handleAddMountingScheme}
                          variant="contained"
                          color="primary"
                        >
                          {t("Add")}
                        </Button>
                      </div>
                      {fullInfo && fullInfo.mountingSchemes ? (
                        <CarMountingSchemesTable
                          data={fullInfo.mountingSchemes}
                          handleEditOrder={handleEditMountingSchemeOrder}
                          handleEditDescription={
                            handleEditMountingSchemeDescription
                          }
                          handleEditLanguage={handleEditMountingSchemeLanguage}
                          handleUpload={handleUploadMountingScheme}
                          handleDownload={handleDownloadMountingScheme}
                          handleDelete={handleDeleteMountingScheme}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                case 4:
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "100%",
                        marginBottom: "16px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          marginBottom: "16px",
                        }}
                      >
                        <Button
                          onClick={handleAddMountingMap}
                          variant="contained"
                          color="primary"
                        >
                          {t("Add")}
                        </Button>
                      </div>
                      {fullInfo && fullInfo.mountingMaps ? (
                        <CarMountingSchemesTable
                          data={fullInfo.mountingMaps}
                          handleEditOrder={handleEditMountingMapOrder}
                          handleEditDescription={
                            handleEditMountingMapDescription
                          }
                          handleEditLanguage={handleEditMountingMapLanguage}
                          handleUpload={handleUploadMountingMap}
                          handleDownload={handleDownloadMountingMap}
                          handleDelete={handleDeleteMountingMap}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                default:
                  return <></>;
              }
            })(tabValue)}
          </div>
        </Grid>
      </Paper>
    </>
  );
}
