import React, { useCallback, useEffect, useState } from "react";
import { HashRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import {
  useUserState,
  checkToken,
  useUserDispatch,
} from "./context/UserContext";
import { useBackend } from "./context/BackendContext";
import { useTranslation } from "react-i18next";

import Main from "./pages/main/Main";
import PasswordRecovery from "./pages/PasswordRecovery";
import ActivateUser from "./pages/ActivateUser";
import MainDownloadsMobile from "./pages/main/MainDownloadsMobile";
import MainDownloadsPc from "./pages/main/MainDownloadsPc";
import MainAutomobiles from "./pages/main/MainAutomobiles";
import MainConfigurations from "./pages/main/MainConfigurations";
import MainPackageSearch from "./pages/main/MainPackageSearch";
import MainLanding from "./pages/main/MainLanding";
import Dashboard from "./pages/dashboard/Dashboard";
import ManageUsers from "./pages/manageUsers/ManageUsers";
import ConfigsQuestions from "./pages/ConfigsQuestions";
import ManageAccount from "./pages/ManageAccount";
import Logs from "./pages/Logs";
import FastMounts from "./pages/FastMounts";
import Clones from "./pages/Clones";
import Pushes from "./pages/Pushes";
import SettingsEditorPage from "./pages/SettingsEditorPage";
import UsergroupPage from "./pages/UsergroupPage";
import TechsupportRequests from "./pages/TechsupportRequests";
import TechsupportRequestFull from "./pages/TechsupportRequestFull";
import ChatPage from "./pages/ChatPage";
import ChatRoomsPage from "./pages/ChatRoomsPage";
import PackageSearchPage from "./pages/PackageSearchPage";
import ManageUpdateChannelFiles from "./pages/ManageUpdateChannelFiles";
import ManageUpdateChannels from "./pages/ManageUpdateChannels";
import ManageUpdateChannelUsers from "./pages/ManageUpdateChannelUsers";
import Mailing from "./pages/Mailing";
import CarBrands from "./pages/CarBrands";
import CarModels from "./pages/CarModels";
import CarEquipments from "./pages/CarEquipments";
import CarEquipmentFull from "./pages/carEquipmentFull/CarEquipmentFull";
import DevicesSubsystems from "./pages/DevicesSubsystems";
import MenuEditor from "./pages/menuEditor/MenuEditor";
import Translations from "./pages/Translations";
import AutohelperLogs from "./pages/AutohelperLogs";
import StatisticsPage from "./pages/StatisticsPage";
import FeedbacksPage from "./pages/FeedbacksPage";
import CloneTips from "./pages/CloneTips";
import OrderRequests from "./pages/OrderRequests";
import FullOrderRequest from "./pages/FullOrderRequest";
import OrderRequestTopics from "./pages/OrderRequestTopics";
import Articles from "./pages/Articles";
import ArticleFull from "./pages/articleFull/ArticleFull";
import DevicesAliases from "./pages/DevicesAliases";
import CarMountingPointGuideTypes from "./pages/CarMountingPointGuideTypes";
import OpenManualRequests from "./pages/OpenManualRequests";
import OpenManualRequestFull from "./pages/OpenManualRequestFull";

export default function App() {
  const userDispatch = useUserDispatch();
  const backend = useBackend();
  const { t } = useTranslation();
  const { isAuthenticated } = useUserState();
  const [headerText, setHeaderText] = useState("Pandora Spec");

  useEffect(() => {
    if (isAuthenticated) {
      checkToken(userDispatch, backend);
    }
  });

  const routes = useCallback(
    () => [
      {
        path: "/app/chatPage",
        header: t("Chat page"),
        component: <ChatPage />,
      },
      {
        path: "/app/chatRoomsPage",
        header: t("Chat rooms page"),
        component: <ChatRoomsPage />,
      },
      {
        path: "/app/techsupportRequests/fullRequest",
        header: null,
        component: <TechsupportRequestFull headerSetter={setHeaderText} />,
      },
      {
        path: "/app/techsupportRequests",
        header: t("Techsupport requests"),
        component: <TechsupportRequests />,
      },
      {
        path: "/app/usergroupPage",
        header: t("Usergroup"),
        component: <UsergroupPage />,
      },
      {
        path: "/app/settingsEditorPage",
        header: t("Settings editor"),
        component: <SettingsEditorPage />,
      },
      { path: "/app/pushes", header: t("Pushes"), component: <Pushes /> },
      { path: "/app/clones", header: t("Clones"), component: <Clones /> },
      { path: "/app/events", header: t("Mount events"), component: <Logs /> },
      {
        path: "/app/fastMounts",
        header: t("Fast mounts"),
        component: <FastMounts />,
      },
      {
        path: "/app/manageAccount",
        header: t("Manage account"),
        component: <ManageAccount />,
      },
      {
        path: "/app/configsQuestions",
        header: t("Configurations"),
        component: <ConfigsQuestions />,
      },
      {
        path: "/app/manageUsers/usergroupPage",
        header: t("Usergroup"),
        component: <UsergroupPage />,
      },
      {
        path: "/app/manageUsers",
        header: t("Users"),
        component: <ManageUsers />,
      },
      {
        path: "/app/dashboard",
        header: t("Main page"),
        component: <Dashboard />,
      },
      {
        path: "/app/packageSearch",
        header: t("Package search"),
        component: <PackageSearchPage />,
      },
      {
        path: "/app/statistics",
        header: t("Statistics"),
        component: <StatisticsPage />,
      },
      {
        path: "/app/manageUpdateChannelFiles",
        header: t("Manage versions"),
        component: <ManageUpdateChannelFiles />,
      },
      {
        path: "/app/manageUpdateChannels",
        header: t("Manage update channels"),
        component: <ManageUpdateChannels />,
      },
      {
        path: "/app/manageUpdateChannelUsers",
        header: t("Manage update channel users"),
        component: <ManageUpdateChannelUsers />,
      },
      {
        path: "/app/feedbacks",
        header: t("Feedbacks"),
        component: <FeedbacksPage />,
      },
      { path: "/app/mailing", header: t("Mailing"), component: <Mailing /> },
      {
        path: "/app/carBrands",
        header: t("Car brands"),
        component: <CarBrands />,
      },
      {
        path: "/app/carModels",
        header: t("Models table"),
        component: <CarModels />,
      },
      {
        path: "/app/carEquipments",
        header: t("Equipments table"),
        component: <CarEquipments />,
      },
      {
        path: "/app/carEquipmentFull",
        header: t("Equipment"),
        component: <CarEquipmentFull />,
      },
      {
        path: "/app/devices",
        header: t("Devices"),
        component: <DevicesSubsystems />,
      },
      {
        path: "/app/menuEditor",
        header: t("Menu editor"),
        component: <MenuEditor />,
      },
      {
        path: "/app/translations",
        header: t("Translations"),
        component: <Translations />,
      },
      {
        path: "/app/carMountingPointGuideTypes",
        header: t("Guide types"),
        component: <CarMountingPointGuideTypes />,
      },
      {
        path: "/app/autohelperLogs",
        header: t("Autohelper"),
        component: <AutohelperLogs />,
      },
      {
        path: "/app/cloneTips",
        header: t("Clone tips"),
        component: <CloneTips />,
      },
      {
        path: "/app/orderRequests/topics",
        header: null,
        component: <OrderRequestTopics />,
      },
      {
        path: "/app/orderRequests/fullRequest",
        header: null,
        component: <FullOrderRequest headerSetter={setHeaderText} />,
      },
      {
        path: "/app/orderRequests",
        header: t("Order requests"),
        component: <OrderRequests />,
      },
      {
        path: "/app/articles/full",
        header: t("Articles"),
        component: <ArticleFull />,
      },
      { path: "/app/articles", header: t("Articles"), component: <Articles /> },
      {
        path: "/app/devicesAliases",
        header: t("Alarm aliases table"),
        component: <DevicesAliases />,
      },
      {
        path: "/app/openManualRequests",
        header: t("Open manuals requests"),
        component: <OpenManualRequests />,
      },
      {
        path: "/app/openManualRequests/full",
        header: null,
        component: <OpenManualRequestFull headerSetter={setHeaderText} />,
      },
    ],
    [t, setHeaderText]
  );

  return (
    <HashRouter>
      <Routes>
        <Route
          path="/main"
          element={
            <Main>
              <Outlet />
            </Main>
          }
        >
          <Route path="/main" element={<MainLanding />} />
          <Route
            path="/main/downloads/android"
            element={<MainDownloadsMobile />}
          />
          <Route path="/main/downloads/pc" element={<MainDownloadsPc />} />
          <Route path="/main/vehicles" element={<MainAutomobiles />} />
          <Route path="/main/configurations" element={<MainConfigurations />} />
          <Route path="/main/packageSearch" element={<MainPackageSearch />} />
        </Route>
        <Route path="/activateUser" element={<ActivateUser />} />
        <Route path="/passwordReset" element={<PasswordRecovery />} />
        <Route
          path="/app"
          element={
            <Layout
              headerText={headerText}
              headerSetter={setHeaderText}
              routes={routes()}
            >
              <Outlet />
            </Layout>
          }
        >
          {routes().map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
        </Route>
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/app/dashboard" replace />
            ) : (
              <Navigate to="/main" replace />
            )
          }
        />
      </Routes>
    </HashRouter>
  );
}
