const unsignedToSignedByte = (v) => {
    var sign = v & (1 << 7);
    var i = (v & 0x7F);
    if (sign) {
        i = i - 128;
    }
    return i;
}

const signedToUnsignedByte = (v) => {
    var sign = v & (1 << 7);
    var i = (v & 0x7F);
    if (sign) {
        i = 128 - i;
    }
    return i;
}

function binToString(array) {
    var result = "";
    for (var i = 0; i < array.length; i++) {
        result += String.fromCharCode(array[i]);
    }
    return result;
}

function stringToBin(str) {
    var result = [];
    for (var i = 0; i < str.length; i++) {
        result.push(str.charCodeAt(i));
    }
    return result;
}

export { unsignedToSignedByte, signedToUnsignedByte, binToString, stringToBin };