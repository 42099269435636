import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { LanguageConverter } from "../../enums/LanguageConverter";
import EquipmentView from "./EquipmentView";
import { yearsStringToSpanString } from "../../helpers/StringUtils";
import { useBackend } from "../../context/BackendContext";

export default function MainAutomobiles() {
  const { t, i18n } = useTranslation();
  const backend = useBackend();
  const [carBrands, setCarBrands] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [carEquipments, setCarEquipments] = useState([]);
  const [searchCarBrandInput, setSearchCarBrandInput] = useState(null);
  const [searchCarModelInput, setSearchCarModelInput] = useState(null);
  const [searchCarEquipmentInput, setSearchCarEquipmentInput] = useState("");
  const [searchCarEquipment, setSearchCarEquipment] = useState(-1);
  const [modelData, setModelData] = useState(null);
  const [equipmentData, setEquipmentData] = useState(null);

  useEffect(() => {
    backend.bckCarsListBrands(false, true, false).then((json) => {
      setCarBrands(json.content);
    });
  }, []);

  const onSelectCarBrand = (...params) => {
    let newValue = params[1];
    setSearchCarBrandInput(newValue);
    setSearchCarModelInput(null);
    setSearchCarEquipment(null);
    setCarModels([]);
    if (newValue) {
      backend.bckCarsListModels(newValue.id, false, true).then((json) => {
        setCarModels(json.content);
      });
    }
  };

  const onSelectCarModel = (...params) => {
    let newValue = params[1];
    setSearchCarModelInput(newValue);
    setSearchCarEquipmentInput(null);
    setCarEquipments([]);
    if (newValue) {
      backend
        .bckCarsListEquipments(
          newValue.id,
          null,
          LanguageConverter[i18n.language]
        )
        .then((json) => {
          setCarEquipments(json.content);
        });
    }
  };

  const onSelectCarEquipment = (...params) => {
    let newValue = params[1];
    setSearchCarEquipmentInput(newValue);
    setSearchCarEquipment(newValue);
  };

  useEffect(() => {
    if (searchCarEquipment) {
      setEquipmentData(null);
      setModelData(null);
      backend
        .bckCarsGetEquipmentInfo(
          searchCarEquipment.id,
          LanguageConverter[i18n.language],
          false
        )
        .then((json) => {
          setEquipmentData(json.content);
          setModelData(
            carModels.find((cm) => cm.id === searchCarModelInput.id)
          );
        });
    }
  }, [searchCarEquipment, i18n.language]);

  return (
    <div
      className={styles.container}
      style={{ paddingTop: "16px", minHeight: "95vh" }}
    >
      <div style={{ width: "100%", maxWidth: "1100px" }}>
        <div style={{ margin: "16px" }}>
          <h2>{t("MAIN_AUTOMOBILES_HEADER")}</h2>
        </div>
        <Paper
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            padding: "16px",
          }}
        >
          <div
            style={{
              flexBasis: "32%",
              marginRight: "16px",
              marginBottom: "16px",
            }}
          >
            <Autocomplete
              value={searchCarBrandInput}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name ?? ""}
              options={
                carBrands == null
                  ? []
                  : carBrands.sort((a, b) => (a.name > b.name ? 1 : -1))
              }
              onChange={onSelectCarBrand}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Car brand")}
                  placeholder={t("Car brand")}
                />
              )}
              disabled={!carBrands || carBrands.length === 0}
              noOptionsText={t("No options")}
              disableClearable
            />
          </div>
          <div
            style={{
              flexBasis: "32%",
              marginRight: "16px",
              marginBottom: "16px",
            }}
          >
            <Autocomplete
              value={searchCarModelInput}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                option.name
                  ? `${option.name} (${yearsStringToSpanString(option.years)})`
                  : ""
              }
              options={
                carModels == null
                  ? []
                  : carModels.sort((a, b) => (a.name > b.name ? 1 : -1))
              }
              onChange={onSelectCarModel}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Car model")}
                  placeholder={t("Car model")}
                />
              )}
              disabled={
                !searchCarBrandInput || !carModels || carModels.length === 0
              }
              noOptionsText={t("No options")}
              disableClearable
            />
          </div>
          <div style={{ flexBasis: "32%", marginBottom: "16px" }}>
            <Autocomplete
              value={searchCarEquipmentInput}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) => option.name ?? ""}
              options={
                carEquipments == null
                  ? []
                  : carEquipments.sort((a, b) => (a.name > b.name ? 1 : -1))
              }
              onChange={onSelectCarEquipment}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Equipment")}
                  placeholder={t("Equipment")}
                />
              )}
              disabled={
                !searchCarModelInput ||
                !carEquipments ||
                carEquipments.length === 0
              }
              noOptionsText={t("No options")}
              disableClearable
            />
          </div>
        </Paper>
        {equipmentData ? (
          <EquipmentView data={equipmentData} modelData={modelData} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
